#root {
    background-color: #3fb54f !important;
}

body {
    margin-right: 0px !important;
}

.App {
    text-align: center;

    min-height: 100vh;
    width: 100vw;
    background-color: #3fb54f !important;
    font-family: "Rubik", sans-serif !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #3fb54f;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    font-family: "Rubik", sans-serif !important;
}

.stamp {
    background-color: #264d26;
    width: 100%;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.makeStyles-root-64 {
    margin-bottom: 20px;
}

.MuiBottomNavigation-root {
    background-color: #264d26 !important;
    color: white;
    font-size: calc(15px + 2vmin) !important;
    margin-bottom: 3% !important;
    margin-right: 0% !important;
    width: 100vw !important;
}

.MuiButton-containedPrimary {
    background-color: #264d26 !important;
    margin-bottom: 20px !important;
    color: antiquewhite;
}

.MuiFormLabel-root.Mui-focused {
    color: black !important;
}

.navIcon {
    padding: 15px;
    padding-left: 8%;
    padding-right: 10%;
}

.submitButton {
    background-color: #264d26;
    border-color: #264d26;
    color: antiquewhite;
}

.MuiAppBar-colorPrimary {
    background-color: #264d26 !important
;
}

.MuiCardContent-root:last-child {
    padding-bottom: 20px !important;
}

.MuiButton-root {
    background-color: #264d26 !important;
}

.MuiSlider-root {
    color: #3fb54f !important;
}

.MuiOutlinedInput-root {
    background-color: white !important;
}

.MuiOutlinedInput-root:focus-within {
    background-color: white !important;
}

.MuiPaper-root {
    margin-left: auto;
    margin-right: auto;
}

.header {
    margin-left: auto !important;
    margin-right: auto !important;
}

.MuiCard-root {
    overflow: hidden;
    margin-bottom: 20px !important;

    margin-left: auto !important;
    margin-right: auto !important;
}

#cityCard {
    width: 80% !important;
    font-family: Rubik sans-serif !important;
    font-weight: 500 !important;
}
.cityCardTitle {
    font-family: "Rubik", sans-serif !important;
    font-weight: 500 !important;
}

.MuiInputBase-input {
    padding-left: 5% !important;
}

.MuiTypography-gutterBottom {
    text-align: center !important;
}

#sliderWindow {
    text-align: center !important;
    padding-left: 4% !important;
    width: 90% !important;
}

.MuiCard-root {
    font-family: "Rubik", sans-serif !important;
}

.MuiTypography-body1 {
    font-family: "Rubik", sans-serif !important;
    font-size: 20px !important;
    font-weight: 600 !important;
}

.MuiTypography-body2 {
    font-family: "Rubik", sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
}

.MuiList-padding {
    padding: 0px !important;
}

/*Admin Sign In Page*/
.signInPic {
    padding-top: 10% !important;
}

.signPassword {
    padding-bottom: 20% !important;
}

.signInPic {
    height: 100% !important;
    width: 18.5% !important;
    padding-top: 2.5%;
    padding-bottom: 5%;
}

/*Admin Home Page*/
.MuiTabs-flexContainer {
    display: block !important;
}

.MuiTabs-fixed {
    width: 80% !important;
}

.MuiBox-root {
    background-color: #3fb54f !important;
}

.signInButton {
    color: white !important;
    text-decoration: none !important;
}

.signInButtonGoogle {
    background-color: #db4437 !important;
    color: white !important;
    text-decoration: none;
}
.bottomSignCard {
    text-align: center !important;
}

.signInButtonDiv {
    display: flex !important;
}

#signInButton {
    flex: 1;
    margin: 1% !important;
    font-size: 20px !important;
}

#signUpButton {
    flex: 1;
    margin: 1% !important;
    font-size: 20px !important;
}

#facebookButton {
    flex: 1;
    margin: 1% !important;
    font-size: 20px !important;
}

#googleButton {
    flex: 1;
    margin: 1%;
    font-size: 20px !important;
}

#emailButton {
    font-size: 20px !important;
    flex: 1 !important;
    margin: 1% !important;
}

.MuiCardMedia-media {
    width: 50%;
    display: inline-block !important;
}

.signUpCard {
    width: 60%;
    margin-top: 2.5rem !important;
    margin-bottom: 5rem !important;
}

.signInCard {
    width: 60%;
    margin-top: 2.5rem !important;
    margin-bottom: 5rem !important;
}

.topAdminCard {
    width: 60%;
    margin-top: 2.5rem !important;
}
.bottomAdminCard {
    width: 60%;
}

.signedOutFavorites {
    margin: 10% !important;
}

.nav {
    transition: 0.25s;
    justify-content: center;
    width: 100vw !important;
    left: 0px !important;
    top: 0px !important;
}

.pleaseFixMyShrink {
    width: 100% !important;
}

.scrolled {
    position: fixed;
    background-color: #264d26 !important;
    color: white;
    font-size: calc(15px + 2vmin) !important;
    width: 100%;
    left: 0px !important;
    top: 0px !important;
    z-index: 2 !important;
}

.MuiExpansionPanel-root {
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.MuiExpansionPanelDetails-root {
    padding: 0 !important;
    display: block !important;
}

.bottomBuffer {
    height: 600px !important;
}

#signOutButton {
    font-size: 18px;
    z-index: 0 !important;
}

#userExpandPannels {
    margin-top: 5% !important;
}

.dateFields {
    display: flex !important;
    width: 80% !important;
    margin-left: 10%;
    margin-bottom: 1rem !important;
    align-self: center;
    justify-content: center;
}

.day {
    flex: 1 !important;
    width: 60% !important;
}

.month {
    flex: 1 !important;
    width: 60% !important;
}

.year {
    flex: 1 !important;
    width: 60% !important;
}

.makeStyles-root-66 {
    width: 80% !important;
    margin-left: 10% !important;
}

.cityCardList {
    margin-top: 2.5% !important;
}
.partyCardList {
    margin-top: 2.5% !important;
}
.partySkeletonCardList {
    margin-top: 2.5% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5%;
}
.citySkeletonList {
    margin-top: 2.5% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.partyFormHeader {
    width: 80%;
    margin-left: 10%;
}

.dateFilterField {
    display: flex !important;
    width: 80% !important;
    margin-left: 5% !important;
}

.dateFilterDay {
    flex: 1;
    width: 60px !important;
}

.dateFilterMonth {
    flex: 2;
    width: 60px !important;
}

.noCard {
    width: 60%;
    margin-top: 2.5rem !important;
    margin-bottom: 5rem !important;
    font-family: "Rubik", sans-serif !important;
    font-weight: 500 !important;
    font-size: 30px;
}

.signInOrUpCard {
    width: 60%;
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.errorMessage {
    color: #e41c38;
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.errorPostMessage {
    color: #e41c38;
}

.timePicker {
    width: 250px !important;
    background-color: white !important;
    margin-bottom: 20px !important;
    border-radius: 5px;
    border: 0px !important;
    height: auto !important;
}

.dateFieldErrorMessages {
    display: flex !important;
    flex-direction: column !important;
    color: #e41c38;
    justify-content: center !important;
}

.hidden {
    display: none !important;
}

.signUpEmailZipDiv {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
}

.signUpPasswordDiv {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
}

.formField {
    width: 50% !important;
}

.formFieldSignIn {
    width: 80% !important;
    margin: 0.5rem !important;
}

.formFieldSignIn:focus {
    border-color: #264d26 !important;
    box-shadow: #264d26 !important;
}

.inputTextField {
    margin: 1% !important;
    flex: 1;
}

.formFieldSignUpZipCode {
    flex: 1 !important;
}

.formFieldSignUpEmail {
    flex: 3 !important;
}

.formFieldSignUp {
    flex: 1 !important;
}

.signUpText {
    margin: 0 !important;
}

.signUpCardImage {
    width: 35% !important;
}

.cityPageTitle {
    color: white;
    font-size: 36px;
}

.cityCreateEventTitleSmall {
    font-size: 28px;
}

.cityCreateEventTitleBig {
    font-size: 36px;
}

.PostMessage {
    color: white;
}

.fade-enter {
    opacity: 0 !important;
    z-index: 1 !important;
}

.fade-enter.fade-enter-active {
    opacity: 1 !important;
    transition: 250ms ease-in !important;
}

.cityPageButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.cityPageButton {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
}

.css-1pahdxg-control {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 50px !important;
}

.css-2b097c-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 50px !important;
}

.css-26l3qy-menu {
    z-index: 1000 !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.css-yk16xz-control {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 50px !important;
}

.GPADiv {
    display: block;
    text-align: center;
    height: 50px !important;
}

.googlePlacesAutocomplete {
    height: 50px !important;
}
